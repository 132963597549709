import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Loader from "../../Component/UI/Loader";
import ReactPaginate from "react-paginate";
import CurrentIssue from "../../Component/Third-PartyAds/CurrentIssue";
// import MustReadSideBar from "../../Component/SideNews/MustReadSideBar";
import { Constants } from "../../Middleware/Constant";
import { DataContext } from "../../Component/Context/SidebarContext";
import SideAd from "../../Component/Third-PartyAds/Advertise";
import TrendingPosts from "../../Component/SideNews/TrendingPost";
import useFetchMetaTags from "../../Middleware/useFetchMetaTags";
import editIcon from "../../assets/img/pencil_2606509 (2).png";
// import { Helmet } from "react-helmet-async";

export default function SearchPage() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("s");
  const page = parseInt(queryParams.get("page")) || 1;

  const {
    data: adsData,
    homeNews: trendingNews,
    user,
  } = useContext(DataContext);
  const ads = adsData?.data;
  const trendingData = trendingNews?.data?.trending_news;
  // const { data: tags } = useFetchMetaTags(query);

  const numberOfPost = 24;

  const fetchResults = async (page) => {
    setLoading(true);
    setError(null);

    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("keyword", query);
    urlEncodedData.append("number_of_posts", numberOfPost);
    urlEncodedData.append("page", page);

    try {
      const response = await fetch(Constants.backendUrl + "search", {
        method: "POST",
        headers: {
          Authorization: `Basic ${Constants.token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlEncodedData.toString(),
        // signal: controller.signal,
      });
      // clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data?.code === 401) {
        throw new Error(data?.message);
      }
      setResults(data);
      setPageCount(Math.ceil(data?.data?.total_posts / numberOfPost));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setCurrentPage(page - 1);
    fetchResults(page);
  }, [location.search]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(data.selected);
    navigate(`?s=${query}&page=${selectedPage}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {" "}
      {/* <Helmet>
        <title>{`Search-${query} | ${tags?.data?.title}`}</title>
        <meta name="description" content={`${tags?.data?.description}`} />
        <link rel="canonical" href={`${tags?.data?.canonical}`} />
        <meta property="og:locale" content={`${tags?.data?.og_locale}`} />
        <meta property="og:type" content={`${tags?.data?.og_type}`} />
        <meta property="og:title" content={`${tags?.data?.og_title}`} />
        <meta
          property="og:description"
          content={`${tags?.data?.og_description}`}
        />
        <meta
          property="og:image"
          content={`https://admin.asiantrader.biz/wp-content/uploads/sites/1/2024/09/AT-logo-White-3-1.png`}
        />
        <meta property="og:url" content={`${tags?.data?.og_url}`} />
        <meta property="og:site_name" content={`${tags?.data?.og_site_name}`} />
        <meta
          property="og:updated_time"
          content={`${tags?.data?.og_updated_time}`}
        />
        <meta
          property="article:published_time"
          content={`${tags?.data?.article_published_time}`}
        />
        <meta
          property="article:modified_time"
          content={`${tags?.data?.article_modified_time}`}
        />
        <meta name="twitter:card" content={`${tags?.data?.twitter_card}`} />
        <meta name="twitter:title" content={`${tags?.data?.twitter_title}`} />
        <meta
          name="twitter:description"
          content={`${tags?.data?.twitter_description}`}
        />
      </Helmet> */}
      <section className="pb-5 news mb-20 bg-blur">
        <div className="container mx-auto sm:px-7 px-3">
          {loading === true ? (
            <>
              <Loader /> <Skeleton height={200} count={5}></Skeleton>
            </>
          ) : error ? (
            <h1 className="container mx-auto px-7">{error}</h1>
          ) : (
            <>
              <div className="flex justify-between items-center sm:px-5 pr-5 border-b border-gray-600 pb-2 mb-5">
                <div>
                  <h1 className="text-[#231E1E] sm:text-2xl text-sm font-bold">
                    Search Results: {query}
                  </h1>
                </div>
                <div className="news-item"></div>
              </div>

              {results?.data?.total_posts !== 0 && query !== null ? (
                <div className="grid grid-cols-12 gap-5">
                  <div className="lg:col-span-9 md:col-span-8 col-span-12">
                    <div className="grid grid-cols-12 gap-5 mb-10">
                      {results?.data?.posts?.map((post, i) => (
                        <div
                          key={i}
                          className="lg:col-span-4 sm:col-span-6 col-span-12"
                        >
                          <div className="rounded-[10px] bg-[#EEEEEE] h-full">
                            <div
                              className={`${
                                user ? "group/item edit-hover relative" : ""
                              }`}
                            >
                              <Link
                                to={`/${post.post_slug}`}
                                aria-label={`${post.post_slug}`}
                                // className="edit-img-hover block relative "
                              >
                                {" "}
                                <img
                                  src={post.post_image}
                                  alt=""
                                  className="w-full lg:h-[170px]  sm:max-h-48 sm:min-h-48 max-h-56 min-h-56 rounded-[10px]   object-cover"
                                  // style={{ width: "100%" }}
                                />
                              </Link>
                              {/* {user && (
                                <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                                  <img
                                    src={editIcon}
                                    className="inline-block h-3 w-3  mb-1"
                                    alt="edit"
                                  />
                                </div>
                              )} */}
                            </div>
                            <div className="px-[23px] pt-[18px] pb-[23px]">
                              <Link to={`/category/${post.cat.cate_slug}`}>
                                <span className="text-[10px] text-[#5e5e5e] font-openSans font-semibold uppercase">
                                  {post.cat.cate_title}
                                </span>
                              </Link>
                              <Link
                                to={`/${post.post_slug}`}
                                aria-label={`${post.post_slug}`}
                              >
                                {" "}
                                <h2 className="text-sm mt-[15px] mb-[10px] font-black wow fadeInDown hover:text-red">
                                  {post.post_title}
                                </h2>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {pageCount > 1 && (
                      <div className="flex sm:justify-between justify-center items-center">
                        <ReactPaginate
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={currentPage}
                        />
                        <div className="text-[#5e5e5e] hidden sm:block  text-[10px]">
                          {`page ${currentPage + 1} of ${pageCount}`}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 col-span-12">
                    <div className="mb-[105px]">
                      <SideAd htmlData={ads?.mpu_1_ads} />
                      <SideAd htmlData={ads?.mpu_2_ads} />
                      <SideAd htmlData={ads?.mpu_3_ads} />
                      <SideAd htmlData={ads?.mpu_4_ads} />
                    </div>
                    <div className="mb-[101px]">
                      <TrendingPosts trendingPost={trendingData} user={user} />
                      {/* <MustReadSideBar /> */}
                    </div>

                    {/* {results?.data?.total_posts?.length > 3 && ( */}
                    <div className="col-span-3 bg-[#231E1E] p-5 rounded-[10px]">
                      <CurrentIssue />
                    </div>
                    {/* )} */}
                    {/* {results?.data?.total_posts?.length > 3 && ( */}
                    <div className="mt-2">
                      <SideAd htmlData={ads?.mpu_5_ads} />
                      <SideAd htmlData={ads?.mpu_6_ads} />
                    </div>
                    {/* )} */}
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-12 gap-5">
                  <div className="lg:col-span-9 md:col-span-8 col-span-12">
                    <p className="text-bold  uppercase ml-10">
                      no post to display
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
}
